.wysiwyg {
    p, ul, ol {
        @apply mb-[calc((28/22)*1em)] last:mb-0;
    }

    h2 {
        @apply font-serif h3 mb-8 m:mb-16;
    }

    h3 {
        @apply font-serif body mb-8;
    }

    b, strong {
        @apply font-bold;
    }

    i, em {
        @apply italic;
    }

    ul {
        @apply pl-[1em] list-disc;
        li::marker {
            @apply text-cta;
        }
    }

    &.enhance-external-links {
        a[target="_blank"] {
            @apply whitespace-nowrap;
            &:after {
                content: '';
                background-image: url('data:image/svg+xml,<svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m1.3672 12.5547-.9375-.9297 9.6094-9.6172H2.3516l.0156-1.289h9.8906v9.9062h-1.3047l.0157-7.6797-9.6016 9.6094Z" fill="%23491E35"/></svg>');
                @apply inline-block size-[13px] ml-4 transition-transform duration-200 ease-out;
            }

            &:hover:after {
                @apply translate-x-2 -translate-y-2;
            }
        }
    }

    li {
        @apply list-item mb-[0.25em] last:mb-0;
    }

    ol {
        @apply list-none;
        counter-reset: ol-counter;
        li {
            counter-increment: ol-counter;
            @apply flex;
            &:before {
                content: counter(ol-counter) ".";
                @apply text-cta mr-[0.5em];
            }
        }
    }

    p.ingress {
        @apply h3 font-serif margins last:mb-0;
    }
}
