@layer components {

    /**
        Værsågod custom Tailwind components
        https://tailwindcss.com/docs/adding-custom-styles#adding-component-classes
     */

    //.btn {
    //    @apply bg-blue m:bg-red;
    //}

    .small {
        @apply text-14 m:text-16 leading-1_3;
    }

    .body {
        @apply text-20 m:text-22 leading-1_3;
    }

    .h3 {
        @apply text-22 m:text-26 leading-1_3;
    }

    .h2 {
        @apply text-26 m:text-32 leading-1_2;
    }

    .h1 {
        @apply text-32 m:text-56 leading-1_2;
    }

    .default-link, .wysiwyg a {
        @apply underline decoration-cta transition-colors duration-350 ease-out hover:decoration-transparent;
    }

    .border-link {
        @apply border-b-1 border-cta transition-colors duration-350 ease-out hover:border-transparent;
    }

    .inverted-links {
        a {
            @apply underline decoration-transparent transition-colors duration-350 ease-out hover:decoration-current;
        }
    }

    .btn-base {
        @apply transition-colors duration-350 ease-in-out rounded-30px px-16 m:px-24 py-3 m:py-8 font-serif text-20 m:text-22 leading-28 m:leading-30 border-1 text-black text-center;
    }

    .btn-yellow {
        @apply btn-base border-yellow bg-yellow hover:border-current;
    }

    .btn-cta {
        @apply btn-base border-cta bg-cta text-yellow-light hover:bg-transparent hover:text-cta;
    }

    .btn-outline {
        @apply btn-base border-cta bg-transparent hover:bg-cta hover:text-white;
    }

    .filter-base {
        @apply transition-colors duration-350 ease-in-out rounded-4px px-16 py-3 border-1 small;
    }

    .filter {
        @apply filter-base border-cta bg-transparent text-black hover:bg-cta hover:text-white-creme active:bg-black active:border-black active:text-white-creme;
    }

    .filter.active {
        @apply bg-black border-black text-white-creme pointer-events-none;
    }

    .tag {
        @apply rounded-4px px-16 py-1 text-black border-1 small transition-colors duration-350 ease-in-out;
    }

    .cart-button {
        filter: drop-shadow(2px 2px 3px rgba(0,0,0,0.15));
    }

    .margins {
        @apply mb-48 m:mb-64;
    }

    [data-block-image] + [data-block-banner] {
        @apply -mt-16 m:-mt-32;
    }

    [data-block-promo] + [data-block-promo] {
        @apply -mt-32 m:-mt-48;
    }

    .cart-button {
        @keyframes bounce {
            from,
            20%,
            53%,
            80%,
            to {
                animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
                transform: translate3d(0, 0, 0);
            }

            40%,
            43% {
                animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
                transform: translate3d(0, -30px, 0);
            }

            70% {
                animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
                transform: translate3d(0, -15px, 0);
            }

            90% {
                transform: translate3d(0, -4px, 0);
            }
        }

        .bounce {
            animation-duration: 1s;
            animation-name: bounce;
            transform-origin: center bottom;
        }
    }
}
