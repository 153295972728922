/* =================
    Flickity reset styles
    https://flickity.metafizzy.co
================== */

.flickity-enabled {
    position: relative;
}

.flickity-enabled:focus {
    outline: none;
}

.flickity-viewport {
    position: relative;
    width: 100%;
    height: 100%;
}

.flickity-slider {
    position: absolute;
    width: 100%;
    height: 100%;
    [data-component="RelatedArticles"] & {
        display: flex;
        position: relative;
    }
}

/* draggable */

.flickity-enabled.is-draggable {
    -webkit-tap-highlight-color: transparent;
    tap-highlight-color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
    cursor: move;
    cursor: -webkit-grab;
    cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
    cursor: -webkit-grabbing;
    cursor: grabbing;
}

.flickity-prev-next-button {
    @apply transition-all duration-350 ease-in-out absolute left-[var(--outer-margin)] size-[44px] rounded-44px top-50 border-1 border-cta bg-white-creme hover:bg-cta text-black hover:text-white -translate-x-50% -translate-y-100% hover:scale-110 disabled:opacity-0;
    &.next {
        @apply left-auto right-[var(--outer-margin)] translate-x-50% -translate-y-100%
    }
    svg path {
        @apply fill-current;
    }
}



/*
.flickity-page-dots {
    @apply flex items-center flex-nowrap absolute -bottom-[40px] center-x bg-grey bg-opacity-50 px-24 py-14 rounded-40px h-[40px];
}

.flickity-page-dots .dot {
    @apply w-[12px] h-[12px] mr-12 last:mr-0 bg-white rounded-6px cursor-pointer;
}

.flickity-page-dots .dot.is-selected {
    @apply bg-purple;
}*/
