:root {
    // Hotfix for Tailwind 3's transform utilities being buggy in Safari <=14
    // https://github.com/tailwindlabs/tailwindcss/discussions/8788
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
}

/* clears the ‘X’ from Internet Explorer */
input[type=search]::-ms-clear { display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal { display: none; width : 0; height: 0; }
/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }

.lazyload,
.lazyloading {
    opacity: 0;
    transform: translateZ(0);
}

.lazyloaded {
    @apply transition-opacity duration-500 ease-out-cubic;
}

html.no-js {
    img.lazyload {
        display: none;
    }
}

html.outline,
html.no-outline * {
    outline: none !important;
}

.image-zoom {
    img {
        position: absolute;
        top: -1px;
        left: -1px;
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        max-width: none;
        will-change: opacity, transform;
        &.lazyloaded {
            transition: transform 0.6s theme('transitionTimingFunction.out-quad'), opacity 0.5s theme('transitionTimingFunction.out-cubic');
        }
    }
}
